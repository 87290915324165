import React from 'react'
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  split,
  gql,
  fromPromise,
  Operation,
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import * as Sentry from '@sentry/react'
import { WebSocketLink } from '@apollo/client/link/ws'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'
import DebounceLink from 'apollo-link-debounce'
import apolloLogger from 'apollo-link-logger'
import merge from 'lodash/fp/merge'
import { RetryLink } from '@apollo/client/link/retry'
import { toast } from 'react-toastify'
import { JwtPayload } from '../../../auth/common/types'

// Fragment matcher
import possibleTypes from '../../graphql/fragments/possibleTypes.json'

import useAuthToken from '../../hooks/auth/useAuthToken'
import useRefreshToken from '../../hooks/auth/useRefreshToken'

// Mutation
import { refreshAccessToken as refreshAccessTokenMutation } from '../../graphql/mutations/refreshAccessToken'

// App resolvers
import { resolvers as portalResolvers } from '../../../portal'
import { StrictTypedTypePolicies } from '../../../graphql/@types/apollo-helpers'
import { CUSTOM_REQUEST_HEADERS, GRAPHQL_URI, PROTOCOL, SERVER_URL } from '../../constants'
import { RequestErrorType } from '../../graphql/utils/parseError'
import { NETWORK_UNAVAILABLE_ERROR_TEXT } from '../../strings'
import {
  OfflineToastContent,
  OfflineToastActionButton,
  OfflineToastMessage,
  OfflineToastHeading,
  OfflineToastBody,
} from '../../styles'
import { decodeJwtToken, parseSubdomain } from '../../helpers/stringUtils'
import useZendeskAuthToken from '../../hooks/auth/useZendeskAuthToken'

const DEFAULT_DEBOUNCE_TIMEOUT = 300

const isLogoutRequest = (operation: Operation) => operation.operationName === 'signOutUser'

// Helpers
const formattedAuthHeader = (token) => (token ? `Bearer ${token}` : '')

interface AuthParams {
  authToken: string
  organizationId: string | null
  subdomain: string
}

const getCommonHeaders = ({ authToken, organizationId, subdomain }: AuthParams) => ({
  Authorization: formattedAuthHeader(authToken),
  [CUSTOM_REQUEST_HEADERS.SUBDOMAIN_HEADER_KEY]: subdomain,
  [CUSTOM_REQUEST_HEADERS.ACCOUNT_ID_HEADER_KEY]: organizationId || '',
})

// Interface.
interface Definintion {
  kind: string
  operation?: string
}

const retryLink = new RetryLink({
  delay: {
    initial: 3000,
  },
  attempts: {
    max: 3,
  },
})

// Initiate the Apollo cache
export const typePolicies: StrictTypedTypePolicies = {
  Ticket: {
    fields: {
      // is there a better way of doing this? getting a warning when trying to merge an empty incoming
      // array with an existing non-empty array
      vendors: {
        merge(existing, incoming) {
          return incoming
        },
      },
      customers: {
        merge(existing, incoming) {
          return incoming
        },
      },
    },
  },
  TicketStatusCategory: {
    keyFields: ['value'],
  },
}

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies,
})

// Client-side state
const resolvers = merge(portalResolvers)
const state = {
  resolvers: resolvers as any, // temporary fix.
  cache,
}

// Server-side links
const httpLink = createUploadLink({
  uri: GRAPHQL_URI,
})
const errorLink = ({
  refreshToken,
  setAuthToken,
  setRefreshToken,
  removeAuthToken,
  removeRefreshToken,
  subdomain,
  setZendeskAuthToken,
  removeZendeskAuthToken,
}) =>
  onError(({ graphQLErrors, operation, forward, networkError }) => {
    if (networkError) {
      toast.error(
        <OfflineToastContent>
          <OfflineToastHeading>Network Error</OfflineToastHeading>
          <OfflineToastBody>
            <OfflineToastMessage>{NETWORK_UNAVAILABLE_ERROR_TEXT}</OfflineToastMessage>
            <OfflineToastActionButton
              onClick={() => {
                location.reload()
              }}
            >
              Retry
            </OfflineToastActionButton>
          </OfflineToastBody>
        </OfflineToastContent>,
        {
          position: 'bottom-left',
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          closeButton: false,
          toastId: 'offline-toast',
        },
      )
    }
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err?.extensions.code) {
          case RequestErrorType.UNAUTHENTICATED:
            // if no refresh token or logout operation, do nothing.
            if (refreshToken == undefined || isLogoutRequest(operation)) {
              if (isLogoutRequest(operation) === false) {
                Sentry.withScope((scope) => {
                  scope.setLevel('info')
                  Sentry.captureException(new Error('No Refresh token found in local storage'), {
                    extra: {
                      operation: operation.operationName,
                      variables: operation.variables,
                    },
                  })
                })
              }
              return
            }
            // renew access token
            return fromPromise(
              refreshAccessTokenMutation(refreshToken, GRAPHQL_URI)
                .then(({ token, refreshToken, zendeskToken }) => {
                  // Store the new tokens for your auth link
                  setAuthToken(token)
                  setRefreshToken(refreshToken)
                  setZendeskAuthToken(zendeskToken)
                  return token
                })
                .catch((e) => {
                  Sentry.withScope((scope) => {
                    scope.setLevel('info')
                    Sentry.captureException(new Error('Failed to fetch refresh token'), {
                      extra: {
                        operation: operation.operationName,
                        variables: operation.variables,
                        message: e?.message,
                      },
                    })
                  })
                  removeAuthToken()
                  removeRefreshToken()
                  removeZendeskAuthToken()
                  // Hack. We need to reload the page. Can not use `useNavigate` hook here.
                  // useNavigate() may be used only in the context of a <Router> component.
                  location.reload()
                }),
            )
              .filter((value) => value != undefined)
              .flatMap((authToken) => {
                const headers = operation.getContext().headers
                const decodedToken = decodeJwtToken<JwtPayload | null>(authToken)
                const organizationId = decodedToken?.organizationId ?? null
                operation.setContext({
                  headers: {
                    ...headers,
                    ...getCommonHeaders({ authToken, organizationId, subdomain }),
                  },
                })
                // retry the request, returning the new observable
                return forward(operation)
              })
          default: {
            Sentry.withScope((scope) => {
              scope.setLevel('info')
              Sentry.captureException(new Error('GraphQL Error'), {
                extra: {
                  operation: operation.operationName,
                  variables: operation.variables,
                },
              })
            })
            return
          }
        }
      }
    }
    if (networkError) {
      Sentry.withScope((scope) => {
        scope.setLevel('info')
        Sentry.captureException(networkError, {
          extra: {
            operation: operation.operationName,
            variables: operation.variables,
          },
        })
      })
    }
  })

const wsLink = ({ authToken, organizationId, subdomain }: AuthParams) => {
  return new WebSocketLink({
    uri: `ws${PROTOCOL}://${SERVER_URL}`,
    options: {
      reconnect: true,
      timeout: 30000,
      connectionParams: {
        ...getCommonHeaders({ authToken, organizationId, subdomain }),
        authToken,
      },
    },
  })
}

const authLink = ({ authToken, organizationId, subdomain }: AuthParams) =>
  setContext(async (_, { headers }) => ({
    headers: {
      ...headers,
      ...getCommonHeaders({ authToken, organizationId, subdomain }),
      // https://www.apollographql.com/docs/apollo-server/security/cors/#graphql-upload
      'Apollo-Require-Preflight': 'true',
    },
  }))

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const serverSideLink = (authParams: AuthParams) =>
  split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation }: Definintion = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink(authParams),
    authLink(authParams).concat(httpLink),
  )

const useConfiguredApolloClient = () => {
  const [authToken, setAuthToken, removeAuthToken] = useAuthToken()
  const [setZendeskAuthToken, removeZendeskAuthToken] = useZendeskAuthToken()
  const [refreshToken, setRefreshToken, removeRefreshToken] = useRefreshToken()

  // decode the token
  const decodedToken = decodeJwtToken<JwtPayload | null>(authToken)

  const organizationId = decodedToken?.organizationId ?? null
  const subdomain = parseSubdomain(window.location.hostname)

  // Create a new ApolloClient, linking our client-side state w/ GraphQL data
  const client = new ApolloClient({
    ...state,
    link: ApolloLink.from([
      errorLink({
        refreshToken,
        setAuthToken,
        setRefreshToken,
        removeAuthToken,
        removeRefreshToken,
        setZendeskAuthToken,
        removeZendeskAuthToken,
        subdomain,
      }),
      retryLink,
      apolloLogger,
      new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
      serverSideLink({ authToken, organizationId, subdomain }),
    ]),
  })

  // TODO: query passed to `client.writeQuery` is incorrect.
  // TODO: Define a default state for the ApolloCache and correct the query.

  // If a resolver resets the ApolloCache, this will automatically setup the default state again
  client.onResetStore(() => {
    return Promise.resolve(
      client.writeQuery({
        query: gql`
          query ResetState {
            defaultState
          }
        `,
        data: undefined,
      }),
    )
  })

  return client
}

export default useConfiguredApolloClient
