import { useCookies } from 'react-cookie'
import { cookieKeys } from '../../constants'
import { convertAnyValidFormatToDate } from '../../helpers/humanReadableTime'

const { ZENDESK_AUTH_TOKEN } = cookieKeys

// custom hook to handle zendeskToken - we use compositon to decouple the auth system and it's storage
const useZendeskAuthToken = () => {
  //we use react-cookies to access our cookies
  const [cookies, setCookie, removeCookie] = useCookies([ZENDESK_AUTH_TOKEN])

  const expiryDate = convertAnyValidFormatToDate(
    process.env.REACT_APP_AUTH_TOKEN_COOKIE_EXPIRES ?? '30d',
  )

  // this function allows to save any string in our cookies, under the key "zendeskToken"
  const setZendeskAuthToken = (zendeskToken) =>
    setCookie(ZENDESK_AUTH_TOKEN, zendeskToken, {
      path: '/',
      ...(expiryDate && {
        expires: expiryDate,
      }),
    })

  //this function removes the key "zendeskToken" from our cookies. Useful to logout
  const removeZendeskAuthToken = () => removeCookie(ZENDESK_AUTH_TOKEN, { path: '/' })

  return [cookies[ZENDESK_AUTH_TOKEN], setZendeskAuthToken, removeZendeskAuthToken] as const
}

export default useZendeskAuthToken
