import '@fontsource/inter'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/captions.css'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { StyledEngineProvider } from '@mui/joy/styles'

import Router from './common/providers/Router'
import ThemeProvider from './common/providers/ThemeProvider'
import DataProvider from './common/providers/DataProvider'
import FeatureProvider from './common/providers/FeatureProvider'
import NotificationProvider from './common/providers/NotificationProvider'
import ZendeskProvider from './common/providers/ZendeskProvider'

console.info(`nodaFi web app version: ${process.env.REACT_APP_VERSION}`)

const App = React.memo(() => {
  return (
    <StyledEngineProvider injectFirst>
      <CookiesProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider>
            <DataProvider>
              <ZendeskProvider>
                <NotificationProvider />
                <FeatureProvider>
                  <Router />
                </FeatureProvider>
              </ZendeskProvider>
            </DataProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </CookiesProvider>
    </StyledEngineProvider>
  )
})

App.displayName = 'App'
export default App
