export function initialize(apiKey: string, onLoad: () => void) {
  if (!!apiKey && typeof window !== 'undefined' && typeof window.zE === 'undefined') {
    const appendScript = function () {
      setTimeout(function () {
        const s = document.createElement('script')
        s.id = 'ze-snippet'
        s.type = 'text/javascript'
        s.async = true
        s.onload = () => {
          // Add ready event listener before calling onLoad
          window.zE('messenger:on', 'ready', () => {
            window.zE('messenger', 'hide')
          })
          onLoad()
        }

        s.src = 'https://static.zdassets.com/ekr/snippet.js?key=' + apiKey

        const x = document.getElementsByTagName('script')[0]
        x?.parentNode.insertBefore(s, x)
      }, 0)
    }

    if (document.readyState === 'complete') {
      appendScript()
    } else if (window.attachEvent) {
      window.attachEvent('onload', appendScript)
    } else {
      window.addEventListener('load', appendScript, false)
    }
  }
}
