import * as React from 'react'
import { ZendeskProvider as ReactUseZendeskProvider } from './react-use-zendesk'

type Props = {
  children: React.ReactNode
}

const ZendeskProvider = React.memo<Props>(({ children }) => {
  const apiKey = process.env.REACT_APP_ZENDESK_API_KEY ?? '2dc1ee9c-b5d8-4a5d-ae0a-c362c703d1ad'
  const handleOpen = () => console.info('Chat become visible')
  const handleUnreadMessages = (count: number) =>
    console.info('You have', count, 'messages to read')
  const handleClose = React.useCallback(() => {
    console.info('Chat become hidden')
  }, [])

  return (
    <ReactUseZendeskProvider
      apiKey={apiKey}
      onOpen={handleOpen}
      onClose={handleClose}
      onUnreadMessages={handleUnreadMessages}
    >
      {children}
    </ReactUseZendeskProvider>
  )
})

ZendeskProvider.displayName = 'ZendeskProvider'
export default ZendeskProvider
